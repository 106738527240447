import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Link from "../atoms/Link"
import Image from "../atoms/Image"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight } from "@fortawesome/sharp-light-svg-icons"

const ContentNavBlock = ({ block }) => {
  return (
    <BlockWrapper block={block} showHeadline="true" centerHeadline="true">
      <div className="flex flex-row flex-wrap mt-8 -mx-grid">
        {block.nav_items?.length > 0 &&
          block.nav_items.map((item, index) => (
            <div
              className="w-full h-36 lg:h-60 sm:w-1/2 lg:w-1/3 -mt-0.5 px-grid"
              key={index}
            >
              <div className="relative block h-full transition-all duration-200 border-t-2 border-b-2 border-ro-red group hover:bg-ro-red">
                {item.image?.filename?.length > 0 && (
                  <>
                    <Image
                      image={item.image}
                      className="object-cover w-full h-full"
                    />
                    <div className="absolute inset-0 transition-all duration-200 bg-ro-black/50 group-hover:bg-ro-red"></div>
                  </>
                )}
                <div
                  className={`absolute top-0 w-full h-full transition-all duration-200 ${
                    item.image?.filename?.length > 0
                      ? "text-ro-white"
                      : "group-hover:text-ro-white"
                  }`}
                >
                  <Link
                    className="block w-full h-full pt-4 px-grid"
                    link={item.link}
                  >
                    {item.title?.length > 0 && (
                      <span className="text-xl font-bold lg:text-3xl">
                        {item.title}
                      </span>
                    )}
                    {item.description?.length > 0 && (
                      <div className="hidden mt-2 lg:block">
                        <span className="">{item.description}</span>
                      </div>
                    )}
                    {item.link_text && (
                      <span className="absolute bottom-0 block pb-4">
                        <FontAwesomeIcon
                          icon={faCaretRight}
                          size="1x"
                          className="inline-block"
                        />{" "}
                        {item.link_text}
                      </span>
                    )}
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default ContentNavBlock
